import { useUserContext } from "../../context/UserContext";
import Checks from "./Checks";
import HeaderPanel from "./HeaderPanel";

export default function ControlPanel() {
  const { isTesting } = useUserContext();

  return (
    <div className="p-5 mt-20 w-full">
      <HeaderPanel />
      {isTesting && <Checks />}
    </div>
  );
}
