import React, { useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import { FaChevronDown } from "react-icons/fa6";
import { useUserContext } from "../../context/UserContext";
import SelectScenario from "./SelectScenario";
import TestHistoryModal from "./TestHistoryModal";

export default function HeaderPanel() {
  const {
    deviceId,
    setDeviceId,
    scenario,
    subscenario,
    isTesting,
    flushDuration,
    setFlushDuration,
    startTesting,
    stopTesting,
  } = useUserContext();
  const [datasetLocation, setDatasetLocation] = useState("development");
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [showHistoryModal, setShowHistoryModal] = useState(false);

  function changeDatasetLocation(datasetLocation) {
    setDatasetLocation(datasetLocation);
    setIsDropdownOpen(false);
  }

  useEffect(() => {
    if (isTesting) {
      setIsDropdownOpen(false);
    }
  }, [isTesting]);

  function start() {
    if (!deviceId || deviceId.trim() === "" || deviceId.length < 8) {
      alert("Enter Valid Device ID");
      return;
    }
    startTesting(deviceId, scenario, subscenario, datasetLocation);
  }
  function stop() {
    stopTesting();
  }

  return (
    <div className="flex relative gap-3 justify-center items-center w-full">
      <span>Device ID: </span>
      <input
        disabled={isTesting}
        type="text"
        placeholder="Device ID"
        className="p-2 px-4 border-2"
        value={deviceId}
        onChange={(e) => setDeviceId(e.target.value)}
      />
      <div id="dropdown" className="relative w-24">
        <button
          disabled={isTesting}
          onClick={() => setIsDropdownOpen(!isDropdownOpen)}
          className="flex gap-2 justify-between items-center py-2 px-4 w-full font-bold text-white bg-indigo-500 rounded hover:bg-indigo-700 disabled:bg-gray-500"
        >
          <span className="capitalize">
            {datasetLocation === "development" ? "Dev" : "Prod"}
          </span>
          <FaChevronDown />
        </button>
        <ul
          id="dropdown-menu"
          className="absolute p-2 w-full bg-gray-100 rounded divide-y divide-black cursor-pointer"
          style={{ display: isDropdownOpen ? "block" : "none" }}
        >
          <li
            className="py-1 hover:bg-gray-200"
            onClick={() => changeDatasetLocation("development")}
          >
            Dev
          </li>
          <li
            className="py-1 hover:bg-gray-200"
            onClick={() => changeDatasetLocation("production")}
          >
            Prod
          </li>
        </ul>
      </div>
      <SelectScenario />
      <button
        onClick={isTesting ? stop : start}
        className="py-2 px-4 font-bold text-white rounded disabled:bg-gray-500 bg-sky-500 hover:bg-sky-700"
      >
        {isTesting ? "Stop" : "Start"}
      </button>
      <button
        onClick={() => setShowHistoryModal(true)}
        className="py-2 px-4 ml-5 font-semibold text-indigo-500 rounded border-2 border-indigo-500 transition hover:text-white hover:bg-indigo-500 disabled:bg-gray-500"
      >
        View History
      </button>
      {scenario === "check_request" && (
        <div className="absolute right-0 mr-5 text-right">
          <p>Flush Duration</p>
          <button
            onClick={() => {
              let res = prompt(
                "Enter flush duration in seconds (leave empty for auto)",
              );
              if (res == null) return;
              else if (res.trim() === "") setFlushDuration(-1);
              else if (isNaN(parseInt(res)) || parseInt(res) <= 0)
                toast.error("Invalid duration for flushing");
              else setFlushDuration(parseInt(res));
            }}
            className="px-2 w-full font-semibold text-white bg-sky-500"
          >
            {flushDuration === -1 ? "auto" : `${flushDuration} s`}
          </button>
        </div>
      )}
      <TestHistoryModal
        visible={showHistoryModal}
        onClose={() => setShowHistoryModal(false)}
      />
    </div>
  );
}
