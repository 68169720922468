import React, { useEffect, useState } from "react";
import { FaChevronDown } from "react-icons/fa6";
import { useUserContext } from "../../context/UserContext";

export default function SelectScenario() {
  const {
    isTesting,
    scenarios,
    scenario,
    setScenario,
    subscenario,
    setSubscenario,
  } = useUserContext();
  const [isScenarioDropdownOpen, setIsScenarioDropdownOpen] = useState(false);
  const [isSubScenarioDropdownOpen, setIsSubScenarioDropdownOpen] =
    useState(false);

  useEffect(() => {
    let sc = scenario || Object.keys(scenarios)[0];
    setScenario(sc);
    setSubscenario(scenarios[sc][0]);
  }, []);

  useEffect(() => {
    if (isTesting) {
      setIsScenarioDropdownOpen(false);
      setIsSubScenarioDropdownOpen(false);
    }
  }, [isTesting]);

  function changeScenario(scenario) {
    setScenario(scenario);
    setSubscenario(scenarios[scenario][0] || []);
    setIsScenarioDropdownOpen(false);
  }
  function changeSubScenario(subScenario) {
    setSubscenario(subScenario);
    setIsSubScenarioDropdownOpen(false);
  }

  return (
    <div className="flex gap-3">
      <div id="scenarioDropdown" className="relative">
        <button
          disabled={isTesting}
          onClick={() => setIsScenarioDropdownOpen(!isScenarioDropdownOpen)}
          className="flex gap-2 justify-between items-center py-2 px-4 w-full font-bold text-white bg-indigo-500 rounded hover:bg-indigo-700 disabled:bg-gray-500"
        >
          <span className="capitalize">{scenario}</span>
          <FaChevronDown />
        </button>
        <ul
          className="absolute p-2 min-w-full bg-gray-100 rounded divide-y divide-black cursor-pointer"
          style={{ display: isScenarioDropdownOpen ? "block" : "none" }}
        >
          {Object.keys(scenarios).map((sc, i) => (
            <li
              key={i}
              className="py-1 hover:bg-gray-200"
              onClick={() => changeScenario(sc)}
            >
              {sc}
            </li>
          ))}
        </ul>
      </div>

      <div
        id="subScenarioDropdown"
        className={`relative ${!scenarios[scenario].length && "hidden"}`}
      >
        <button
          disabled={isTesting}
          onClick={() =>
            setIsSubScenarioDropdownOpen(!isSubScenarioDropdownOpen)
          }
          className="flex gap-2 justify-between items-center py-2 px-4 w-full font-bold text-white bg-indigo-500 rounded hover:bg-indigo-700 disabled:bg-gray-500"
        >
          <span className="capitalize">{subscenario}</span>
          <FaChevronDown />
        </button>
        <ul
          className="absolute p-2 min-w-full bg-gray-100 rounded divide-y divide-black cursor-pointer"
          style={{ display: isSubScenarioDropdownOpen ? "block" : "none" }}
        >
          {scenarios[scenario].map((subScenario, i) => (
            <li
              key={i}
              className="py-1 capitalize hover:bg-gray-200"
              onClick={() => changeSubScenario(subScenario)}
            >
              {subScenario}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}
