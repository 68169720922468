import { getCheckRequestTemplate } from "../../utils/checkRequestTemplates";
import { getEmergencyTemplate } from "../../utils/emergencyTemplates";
import { getMasterTemplate } from "../../utils/masterTemplates";
import types from "../../utils/testTypes";

export function getTemplate(check, subscenario) {
  switch (check) {
    case types.default_on:
      return { ...defaultOnTemplate };
    case types.emergency:
      return { ...getEmergencyTemplate(subscenario) };
    case types.master:
      return { ...getMasterTemplate() };
    case types.check_request:
      return { ...getCheckRequestTemplate(subscenario) };
    case types.reset_wifi:
      return { ...resetWifiTemplate };
    case types.termination:
      return { ...terminationTemplate };
    case types.dnd:
      return { ...dndTemplate };
    case types.reboot:
      return { ...rebootTemplate };
    default:
      return null;
  }
}

const defaultOnTemplate = {
  connectivity: {
    name: "connectivity",
    status: "running",
  },
  "default on": {
    name: "default on",
    status: "waiting",
  },
  "sediment flush": {
    name: "sediment flush",
    status: "waiting",
  },
};

const resetWifiTemplate = {
  connectivity: {
    name: "connectivity",
    status: "running",
  },
  disconnection: {
    name: "disconnection",
    status: "waiting",
  },
  "reconnect wifi": {
    name: "reconnect wifi",
    comments: "Please reconnect wifi by going to 192.168.4.1 or using app",
    input: {
      clicked: false,
      dependency: "disconnection",
    },
  },
  reconnection: {
    name: "reconnection",
    status: "waiting",
  },
};

const terminationTemplate = {
  connectivity: {
    name: "connectivity",
    status: "running",
  },
  "flush start": {
    name: "flush start",
    status: "waiting",
  },
  termination: {
    name: "termination",
    status: "waiting",
  },
  "notification triggered": {
    name: "notification triggered",
    status: "waiting",
  },
  "notification received In-App": {
    name: "notification received In-App",
    comments: "Did you receive notification from the app ?",
    input: {
      clicked: false,
      dependency: "notification",
    },
  },
  "notification received WhastApp": {
    name: "notification received WhastApp",
    comments: "Did you receive notification from WhastApp ?",
    input: {
      clicked: false,
      dependency: "notification triggered",
    },
  },
};

const dndTemplate = {
  connectivity: {
    name: "connectivity",
    status: "running",
  },
  "requests sent": {
    name: "requests sent",
    status: "waiting",
  },
  notification: {
    name: "notification",
    status: "waiting",
  },
  "voice prompt": {
    name: "voice prompt",
    comments: "did you hear any voice prompt ?",
    input: {
      clicked: false,
      dependency: "requests sent",
    },
  },
};

const rebootTemplate = {
  connectivity: {
    name: "connectivity",
    status: "running",
  },
  "reboot payload": {
    name: "reboot payload",
    status: "waiting",
  },
  reboot: {
    name: "reboot",
    comments: "did the device reboot?",
    input: {
      clicked: false,
      dependency: "reboot payload",
    },
  },
};
