import React, { createContext, useContext, useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import { io } from "socket.io-client";
import { useUserContext } from "./UserContext";

export const UserContext = createContext({
  debugDeviceId: "F02880CD31E8",
  setDebugDeviceId: () => {},
  abortData: null,
  isDebugging: false,
  debugLogs: [],
  startDebug: () => {},
  stopDebug: () => {},
});

export const useDebugContext = () => {
  return useContext(UserContext);
};

export default function DebugContextProvider({ children }) {
  const [isDebugging, setIsDebugging] = useState(false);
  const [debugDeviceId, setDebugDeviceId] = useState("10F820BD9710");
  const [debugLogs, setDebugLogs] = useState([]);
  const [abortData, setAbortData] = useState();
  const [debugSocket, setDebugSocket] = useState();
  const { token } = useUserContext();

  function disconnectDebugSocket() {
    if (debugSocket) {
      debugSocket.off("message", onDebugMessage);
      debugSocket.off("connect");
      debugSocket.off("disconnect");
      debugSocket.off("error");
      setDebugSocket();
    }
  }

  async function startDebug(deviceId) {
    const sock = io(process.env.REACT_APP_WS_URL);
    try {
      await new Promise((resolve) =>
        sock.on("connect", () => {
          toast.success("Session connected to the backend");
          resolve();
        }),
      );
      sock.on("error", (err) => {
        toast.error(err);
      });
      sock.on("disconnect", (reason) => {
        toast.error(reason);
      });
      sock.on("message", (data) => {
        onDebugMessage(data);
      });
    } catch (err) {
      toast.error("Session not connected to the backend");
      return;
    }
    sock.send(
      JSON.stringify({
        type: "start-debug",
        deviceId: debugDeviceId,
        token,
      }),
    );
    setDebugSocket(sock);
    setIsDebugging(true);
    setDebugLogs([]);
  }
  function stopDebug() {
    if (!token || !debugSocket || !debugSocket.connected) {
      toast.error("Session not connected to the backend");
      return;
    }
    console.log({
      deviceId: debugDeviceId,
      type: "stop-debug",
      token,
    });
    debugSocket.send(
      JSON.stringify({
        deviceId: debugDeviceId,
        type: "stop-debug",
        token,
      }),
    );
    setIsDebugging(false);
    disconnectDebugSocket();
  }

  function onDebugMessage(response) {
    if (typeof response === "string") response = JSON.parse(response);
    if (response.type === "debug") {
      setDebugLogs((prev) => {
        return [...prev, response];
      });
    } else if (response.type === "abort") {
      setAbortData(response);
    }
  }

  return (
    <UserContext.Provider
      value={{
        debugDeviceId,
        setDebugDeviceId,
        debugLogs,
        abortData,
        isDebugging,
        startDebug,
        stopDebug,
      }}
    >
      {children}
    </UserContext.Provider>
  );
}
