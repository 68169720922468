import axios from "axios";
import { useEffect, useState } from "react";
import { confirmAlert } from "react-confirm-alert";
import { toast } from "react-hot-toast";
import { FaChevronDown } from "react-icons/fa6";
import { useUserContext } from "../../context/UserContext";

const locations = ["development", "production"];

export default function DeviceConfig() {
  const { token, deviceId: device_id } = useUserContext();
  const [deviceId, setDeviceId] = useState(device_id);
  const [datasetLocation, setDatasetLocation] = useState(locations[0]);
  const [availableNumbers, setAvailableNumbers] = useState([]);
  const [numberAttached, setNumberAttached] = useState(availableNumbers[0]);
  const [isDatasetDropdownOpen, setIsDatasetDropdownOpen] = useState(false);
  const [isNumberDropdownOpen, setIsNumberDropdownOpen] = useState(false);
  const [minMaxValues, setMinMaxValues] = useState({});

  useEffect(() => {
    if (!token) return;
    if (!deviceId || deviceId.length < 12) return;
    (async () => {
      try {
        const res = await axios.get(
          `${process.env.REACT_APP_API_URL}/deviceconfig/attached-number/${deviceId}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          },
        );
        if (res.status !== 200) throw new Error(res.data.message);
        setAvailableNumbers(res.data.data);
        setNumberAttached(res.data.data[0]);
      } catch (err) {
        console.error(err);
        toast.error(err.response?.data?.message ?? err.message);
      }
    })();

    (async () => {
      try {
        const res = await axios.get(
          `${process.env.REACT_APP_API_URL}/deviceconfig/get-specific-values/${deviceId}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          },
        );
        setMinMaxValues(res.data);
      } catch (err) {
        if (err.response?.status === 404) {
          setMinMaxValues(err.response.data.defaults);
          return;
        }
        console.error(err);
        toast.error(err.response?.data?.message ?? err.message);
      }
    })();
  }, [deviceId, token]);

  function changeDatasetLocation(datasetLocation) {
    setDatasetLocation(datasetLocation);
    setIsDatasetDropdownOpen(false);
  }
  function attachNumber(num) {
    setNumberAttached(num);
    setIsNumberDropdownOpen(false);
  }
  function attachMobileNumber() {
    if (!token) return;
    if (!deviceId || deviceId.trim() === "" || deviceId.length < 12) {
      alert("Enter Valid Device ID");
      return;
    }
    if (!numberAttached) {
      alert("Select a number");
      return;
    }
    (async () => {
      try {
        let res = await axios.post(
          `${process.env.REACT_APP_API_URL}/deviceconfig/attach-number`,
          {
            device_id: deviceId,
            mobile_number: numberAttached,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          },
        );
        if (res.status !== 200) throw new Error(res.data.message);
        toast.success("Number attached successfully");
      } catch (e) {
        console.error(e);
        toast.error(e.response?.data?.message ?? e.message);
      }
    })();
  }
  async function saveMinMaxValues() {
    try {
      let res = await axios.patch(
        `${process.env.REACT_APP_API_URL}/deviceconfig/update-specific-values`,
        {
          ...minMaxValues,
          device_id: deviceId,
          deviceOnly: false,
          changeInBackup: true,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      );
      if (res.status !== 200) throw new Error(res.data.message);
      toast.success("Min Max Values Saved successfully");
    } catch (e) {
      console.error(e);
      toast.error(e.response?.data?.message ?? e.message);
    }
  }

  async function factoryReset() {
    if (!deviceId || deviceId.trim() === "" || deviceId.length < 12) {
      toast.error("Enter Valid Device ID");
      return;
    }
    confirmAlert({
      title: "Confirm",
      message: "Are you sure you want to reset this device?",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            factoryResetDevice();
          },
        },
        {
          label: "No",
          onClick: () => {},
        },
      ],
    });
    async function factoryResetDevice() {
      try {
        let res = await axios.post(
          `${process.env.REACT_APP_API_URL}/deviceconfig/factory-reset`,
          {
            device_id: deviceId,
            factory_reset: 0,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          },
        );
        if (res.status !== 200) throw new Error(res.data.message);
        toast.success("Device factory reset successfully");
      } catch (e) {
        console.error(e);
        toast.error(e.response?.data?.message ?? e.message);
      }
    }
  }

  return (
    <div className="relative mt-20 w-full">
      <div className="flex gap-3 justify-center items-center w-full">
        <span>Device ID: </span>
        <input
          type="text"
          placeholder="Device ID"
          className="p-2 px-4 border-2"
          value={deviceId}
          onChange={(e) => setDeviceId(e.target.value)}
        />
      </div>
      {deviceId && deviceId.length >= 12 && (
        <div className="px-5 w-full">
          <h2 className="mt-10 text-2xl">
            Change Dataset Location and MQTT URL
          </h2>
          <div className="flex relative gap-10 items-end p-5 rounded-lg shadow-lg">
            <div id="datasetDropdown" className="relative w-max border-2">
              <button
                onClick={() => setIsDatasetDropdownOpen(!isDatasetDropdownOpen)}
                className="flex gap-2 justify-between items-center py-2 px-4 w-full font-bold rounded"
              >
                <span className="capitalize">{datasetLocation}</span>
                <FaChevronDown />
              </button>
              <ul
                className="absolute p-2 w-full bg-gray-100 rounded divide-y divide-black cursor-pointer"
                style={{ display: isDatasetDropdownOpen ? "block" : "none" }}
              >
                {locations.map((loc, i) => (
                  <li
                    key={i}
                    className="py-1 hover:bg-gray-200"
                    onClick={() => changeDatasetLocation(loc)}
                  >
                    {loc}
                  </li>
                ))}
              </ul>
            </div>
            <div>
              <p className="text-lg">Full MQTT URL</p>
              <input
                type="url"
                placeholder="mqtts://mqtt-dev.komsup.com:8883/"
                className="py-1.5 px-2 w-96 text-lg border border-black"
              />
            </div>
            <button className="py-2 px-5 text-lg font-bold text-white bg-indigo-500 rounded hover:bg-indigo-700 active:bg-indigo-600">
              SEND
            </button>
          </div>
          <h2 className="mt-10 text-2xl">Reset device to factory settings</h2>
          <div className="p-5 rounded-lg shadow-lg">
            <button
              onClick={factoryReset}
              className="py-2 px-5 text-lg font-bold text-white bg-red-500 rounded hover:bg-red-700 active:bg-red-600"
            >
              Factory Reset
            </button>
          </div>
          <h2 className="mt-10 text-2xl">Attach mobile number to device</h2>
          <div className="p-5 rounded-lg shadow-lg">
            <div className="flex relative gap-3 items-center">
              <span>Mobile No: </span>
              <div className="relative">
                <input
                  type="tel"
                  className="p-2 px-4 border-2 shrink-0 grow-0 basis-44"
                  value={numberAttached}
                  onFocus={() => setIsNumberDropdownOpen(true)}
                  onChange={(e) => setNumberAttached(e.target.value)}
                />
                {isNumberDropdownOpen && (
                  <ul className="absolute p-2 w-full bg-gray-100 rounded divide-y divide-black shadow-lg cursor-pointer">
                    {availableNumbers
                      .filter((num) => num.includes(numberAttached))
                      .map((num, i) => (
                        <li
                          key={i}
                          className="py-1 hover:bg-gray-200"
                          onClick={() => attachNumber(num)}
                        >
                          {num}
                        </li>
                      ))}
                  </ul>
                )}
              </div>
              <button
                className="py-2 px-5 ml-12 text-lg font-bold text-white bg-indigo-500 rounded hover:bg-indigo-700 active:bg-indigo-600"
                onClick={attachMobileNumber}
              >
                Attach
              </button>
            </div>
          </div>
          <h2 className="mt-10 text-2xl">Set Min Max Values</h2>
          <div className="p-5 rounded-lg shadow-lg">
            <div className="space-y-1">
              <h2 className="text-xl font-semibold">Emergency</h2>
              {Object.keys(minMaxValues.emergency ?? Object()).map((key, i) => (
                <div key={i} className="flex gap-3 items-center">
                  <span className="w-40">{key}</span>
                  <input
                    type="number"
                    value={minMaxValues.emergency[key]}
                    className="p-2 px-4 w-36 border-2"
                    onChange={(e) =>
                      setMinMaxValues({
                        ...minMaxValues,
                        emergency: {
                          ...minMaxValues.emergency,
                          [key]: e.target.value,
                        },
                      })
                    }
                  />
                </div>
              ))}
            </div>
            <div className="mt-5 space-y-1">
              <h2 className="text-xl font-semibold">Master</h2>
              {Object.keys(minMaxValues.master ?? Object()).map((key, i) => (
                <div key={i} className="flex gap-3 items-center">
                  <span className="w-48">{key}</span>
                  <input
                    type="number"
                    value={minMaxValues.master[key]}
                    className="p-2 px-4 w-28 border-2"
                    onChange={(e) =>
                      setMinMaxValues({
                        ...minMaxValues,
                        master: {
                          ...minMaxValues.master,
                          [key]: e.target.value,
                        },
                      })
                    }
                  />
                </div>
              ))}
            </div>
            <button
              onClick={saveMinMaxValues}
              className="py-2 px-5 mt-5 text-lg font-bold text-white bg-indigo-500 rounded hover:bg-indigo-700 active:bg-indigo-600"
            >
              Save Min-Max Values
            </button>
          </div>
        </div>
      )}
    </div>
  );
}
