import axios from "axios";
import React, { useRef, useState } from "react";
import { toast } from "react-hot-toast";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useUserContext } from "../context/UserContext";

export default function Login() {
  const [otp, setOtp] = useState(["", "", "", ""]);
  const otpRefs = useRef([]);
  const [otpSent, setOtpSent] = useState(false);
  const [error, setError] = useState("");
  const [phone, setPhone] = useState("");
  const [loading, setLoading] = useState(false);
  const { login } = useUserContext();
  const navigate = useNavigate();
  const to = useSearchParams()[0].get("to");

  async function otpInput(e, index) {
    setError();
    if (otpRefs.current.length === 0) return;
    if (e.target.value.length > 1) return;
    const newOtp = [...otp];
    newOtp[index] = e.target.value;
    setOtp(newOtp);
    if (index < otpRefs.current.length - 1 && e.target.value !== "") {
      otpRefs.current[index + 1].focus();
    }
    if (newOtp.join("").length === 4) {
      await verifyOtp(newOtp.join(""));
      return;
    }
  }
  async function otpKeyDown(e, index) {
    if (e.key === "Backspace" && index > 0 && otp[index] === "") {
      otpRefs.current[index - 1]?.focus();
    }
  }

  async function verifyOtp(otp) {
    try {
      let res = await axios.post(
        `${process.env.REACT_APP_API_URL}/admin/auth/verify-otp`,
        {
          countryCode: "+91",
          mobileNumber: phone,
          otp,
        },
      );
      if (res.status === 200) {
        toast.success("OTP verified successfully");
        setOtpSent(false);
        login(res.data.message.accessToken, res.data.message.role, phone);
        navigate(`${to || "/"}`, { replace: true });
      }
    } catch (e) {
      toast.error(e.response.data.message);
      setError(e.response.data.message);
      setOtpSent(true);
    }
  }

  async function sendOtp() {
    if (!phone || phone.trim().length < 10) {
      toast.error("Please enter a valid mobile number");
      return;
    }
    setLoading(true);
    try {
      let res = await axios.post(
        `${process.env.REACT_APP_API_URL}/admin/auth/send-otp`,
        {
          countryCode: "+91",
          mobileNumber: phone,
        },
      );
      if (res.status === 200) {
        toast.success("OTP sent successfully");
        setOtpSent(true);
      }
    } catch (e) {
      toast.error(e.response.data.message);
      setError(e.response.data.message);
      setOtpSent(false);
    }
    setLoading(false);
  }

  return (
    <div className="grid w-full min-h-screen">
      <div className="flex flex-col gap-5 place-self-center w-96">
        <h1 className="mb-5 text-4xl font-bold text-center">Login</h1>
        <input
          type="tel"
          placeholder="Mobile number"
          className="p-2 px-4 border border-black disabled:opacity-50"
          disabled={otpSent}
          value={phone}
          onChange={(e) => setPhone(e.target.value)}
        />
        {/* otp field containing 4 inputs */}
        {otpSent && (
          <div>
            <div className="flex gap-2 justify-center items-center">
              <h2 className="mr-5 text-xl">Enter OTP:</h2>
              {otp.map((digit, index) => (
                <input
                  key={index}
                  type="text"
                  className="text-xl text-center border border-black disabled:opacity-50 size-12"
                  maxLength={1}
                  value={digit}
                  onInput={(e) => otpInput(e, index)}
                  onKeyDown={(e) => otpKeyDown(e, index)}
                  ref={(ref) => (otpRefs.current[index] = ref)}
                />
              ))}
            </div>
          </div>
        )}
        {error && (
          <p className="font-semibold text-red-500">
            <span className="text-2xl">⚠︎</span> {error}
          </p>
        )}
        <button
          onClick={sendOtp}
          className={`p-2 px-4 text-xl font-semibold text-white bg-indigo-500 rounded ${otpSent && "hidden"}`}
        >
          {loading ? (
            <div className="size-8 lds-ring">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
          ) : (
            "Get OTP"
          )}
        </button>
      </div>
    </div>
  );
}
