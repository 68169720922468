import { CSVLink } from "react-csv";
import "react-confirm-alert/src/react-confirm-alert.css";
import types from "../../utils/testTypes.js";
import { useUserContext } from "../../context/UserContext.jsx";

export default function Checks() {
  const {
    scenario,
    checksData,
    csvData,
    abortData,
    checkingProgress,
    manualConfirm,
    fixMinMax,
  } = useUserContext();

  return (
    <div className="p-8 w-full">
      <div className="flex justify-between items-center mb-5">
        <div>
          <h1 className="text-2xl font-bold">{scenario} Tests</h1>
          <div className="text-lg">
            {checkingProgress ? (
              <p className="text-gray-500">Running...</p>
            ) : abortData ? (
              <p className="text-red-500">
                Aborted ( Reason: {abortData.reason} )
              </p>
            ) : (
              <p className="text-green-500">Completed</p>
            )}
          </div>
        </div>
        {(scenario === types.emergency || scenario === types.master) && (
          <button
            onClick={fixMinMax}
            className="py-1 px-2 text-lg text-white bg-red-500 rounded-lg active:bg-red-600"
          >
            Fix min-max
          </button>
        )}
      </div>
      <table className="w-full">
        <tbody>
          <tr className="text-gray-400 border-b border-gray-500">
            <th>Checks</th>
            <th>Status</th>
            <th>Last Run</th>
            <th>Duration(s)</th>
            <th>Comments</th>
            <th>Data Link</th>
            <th>Result</th>
          </tr>
          {Object.keys(checksData).map((c, i) => (
            <tr key={i} className="py-3">
              <td className="text-lg capitalize text-sky-700">
                {checksData[c].name}
              </td>
              {checksData[c].status ? (
                <td className="capitalize">{checksData[c].status}</td>
              ) : checksData[c].input ? (
                <td className="capitalize">
                  <button
                    onClick={() => {
                      manualConfirm(c);
                    }}
                    className={`py-1 px-2 text-white capitalize rounded-lg bg-sky-600 hover:bg-sky-800 disabled:bg-gray-500`}
                    disabled={
                      checksData[c].input.clicked ||
                      (checksData[c].input.dependency &&
                        checksData[checksData[c].input.dependency]?.result !==
                          "success")
                    }
                  >
                    Select
                  </button>
                </td>
              ) : (
                <td className="capitalize">N/A</td>
              )}
              <td>
                {checksData[c].lastRun
                  ? new Date(checksData[c].lastRun).toLocaleString()
                  : "N/A"}
              </td>
              <td>{checksData[c].duration}</td>
              <td className="whitespace-pre-wrap">{checksData[c].comments}</td>
              <td>
                <CSVLink
                  data={csvData[c] ?? []}
                  filename={`${c}_${new Date().toISOString()}.csv`}
                  target="_blank"
                  className="text-sm text-blue-500"
                >
                  Download CSV
                </CSVLink>
              </td>
              <td className="uppercase result">
                <div
                  className={`${checksData[c].result && (checksData[c].result === "success" ? "bg-green-500" : checksData[c].result === "failed" ? "bg-red-500" : "bg-orange-500")}`}
                >
                  {checksData[c].result}
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}
