const masterChecks = {
  connectivity: {
    name: "connectivity",
    status: "running",
  },
  "emergency undecided": {
    name: "emergency undecided",
    status: "waiting",
  },
  "undecided notification triggered": {
    name: "undecided notification triggered",
    status: "waiting",
  },
  "undecided notification received In-App": {
    name: "undecided notification received In-App",
    comments: "Did you receive notification from the app ?",
    input: {
      clicked: false,
      dependency: "undecided notification triggered",
    },
  },
  "undecided notification received WhastApp": {
    name: "undecided notification received WhastApp",
    comments: "Did you receive notification from WhastApp ?",
    input: {
      clicked: false,
      dependency: "undecided notification triggered",
    },
  },
  "master program": {
    name: "master program",
    status: "waiting",
  },
  "emergency created": {
    name: "emergency created",
    status: "waiting",
  },
  "notification triggered": {
    name: "notification triggered",
    status: "waiting",
  },
  "notification received In-App": {
    name: "notification received In-App",
    comments: "Did you receive notification from the app ?",
    input: {
      clicked: false,
      dependency: "notification triggered",
    },
  },
  "notification received WhastApp": {
    name: "notification received WhastApp",
    comments: "Did you receive notification from WhastApp ?",
    input: {
      clicked: false,
      dependency: "notification triggered",
    },
  },
  "ticket raised": {
    name: "ticket raised",
    status: "waiting",
  },
  "emergency resolved": {
    name: "emergency resolved",
    status: "waiting",
  },
  "resolved notification triggered": {
    name: "resolved notification triggered",
    status: "waiting",
  },
  "resolved notification received In-App": {
    name: "resolved notification received In-App",
    comments: "Did you receive notification from the app ?",
    input: {
      clicked: false,
      dependency: "resolved notification triggered",
    },
  },
  "resolved notification received WhastApp": {
    name: "resolved notification received WhastApp",
    comments: "Did you receive notification from WhastApp ?",
    input: {
      clicked: false,
      dependency: "resolved notification triggered",
    },
  },
  "ticket closed": {
    name: "ticket closed",
    status: "waiting",
  },
};

const nonMasterChecks = {
  connectivity: {
    name: "connectivity",
    status: "running",
  },
  "emergency created": {
    name: "emergency created",
    status: "waiting",
  },
  "notification triggered": {
    name: "notification triggered",
    status: "waiting",
  },
  "notification received In-App": {
    name: "notification received In-App",
    comments: "Did you receive notification from the app ?",
    input: {
      clicked: false,
      dependency: "notification triggered",
    },
  },
  "notification received WhastApp": {
    name: "notification received WhastApp",
    comments: "Did you receive notification from WhastApp ?",
    input: {
      clicked: false,
      dependency: "notification triggered",
    },
  },
  "ticket raised": {
    name: "ticket raised",
    comments: "Did you raise the ticket ?",
    input: {
      clicked: false,
      dependency: "notification received In-App",
    },
  },
  "emergency resolved": {
    name: "emergency resolved",
    status: "waiting",
  },
  "resolved notification triggered": {
    name: "resolved notification triggered",
    status: "waiting",
  },
  "resolved notification received In-App": {
    name: "resolved notification received In-App",
    comments: "Did you receive notification from the app ?",
    input: {
      clicked: false,
      dependency: "resolved notification triggered",
    },
  },
  "resolved notification received WhastApp": {
    name: "resolved notification received WhastApp",
    comments: "Did you receive notification from WhastApp ?",
    input: {
      clicked: false,
      dependency: "resolved notification triggered",
    },
  },
  "ticket closed": {
    name: "ticket closed",
    status: "waiting",
  },
};

export function getEmergencyTemplate(subscenario) {
  let isMasterReq = !["pr1_check", "tds1_check", "leakage_check"].includes(
    subscenario,
  );

  let checks = isMasterReq ? { ...masterChecks } : { ...nonMasterChecks };
  if (["leakage_check"].includes(subscenario)) {
    delete checks["ticket raised"].input;
    delete checks["ticket raised"].comments;
    checks["ticket raised"].status = "waiting";
  }
  return checks;
}
