import axios from "axios";
import { useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import { IoMdClose } from "react-icons/io";
import { FaArrowDown, FaChevronLeft, FaChevronRight } from "react-icons/fa6";
import { ImSpinner2 } from "react-icons/im";
import { useUserContext } from "../../context/UserContext";
import { CSVLink } from "react-csv";

export default function TestHistoryModal({ visible, onClose }) {
  const { deviceId, scenario, subscenario } = useUserContext();
  const [testList, setTestList] = useState([]);
  const [testId, setTestId] = useState();
  const [testDetails, setTestDetails] = useState();
  const [loadingTests, setLoadingTests] = useState(false);
  const [filterDate, setFilterDate] = useState("");
  const [pageNumber, setPageNumber] = useState(1);
  const [noItems, setNoItems] = useState(false);
  const pageSize = 10;

  function downloadTextFile(filename, text) {
    const element = document.createElement("a");
    element.setAttribute(
      "href",
      `data:text/plain;charset=utf-8,${encodeURIComponent(text)}`,
    );
    element.setAttribute("download", filename);
    element.style.display = "none";
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
  }

  useEffect(() => {
    if (!visible || !deviceId) return setFilterDate("");
    let API = new URL(
      `${process.env.REACT_APP_SCRIPT_API_URL}/testing/get-test-list`,
    );
    API.searchParams.append("pageNumber", pageNumber);
    API.searchParams.append("pageSize", pageSize);
    setLoadingTests(true);
    (async () => {
      let body = {
        deviceId,
        scenario,
        subScenario: subscenario,
      };
      if (filterDate && filterDate !== "") body.date = filterDate;
      try {
        let res = await axios.post(API.toString(), body);
        if (res.status !== 200)
          throw new Error(res.data?.data?.message ?? res.statusText);
        setTestList(res.data);
        setNoItems(res.data.length === 0);
      } catch (e) {
        console.error(e);
        toast.error(e.response?.data?.message ?? e.message);
      } finally {
        setLoadingTests(false);
      }
    })();
  }, [visible, deviceId, filterDate, pageNumber, scenario, subscenario]);

  useEffect(() => {
    setPageNumber(1);
    setNoItems(false);
  }, [filterDate, deviceId]);

  useEffect(() => {
    setTestList([]);
    setTestId();
    setTestDetails();
    setNoItems(false);
    setFilterDate("");
  }, [deviceId]);

  useEffect(() => {
    if (!testId || !visible) return;
    setLoadingTests(true);
    (async () => {
      try {
        let res = await axios.get(
          `${process.env.REACT_APP_SCRIPT_API_URL}/testing/test-details/${testId}`,
        );
        if (res.status !== 200)
          throw new Error(res.data?.data?.message ?? res.statusText);
        setTestDetails(res.data);
        if (res.data.length === 0) setNoItems(true);
        document.getElementById("container").scrollTop = 0;
      } catch (e) {
        console.error(e);
        toast.error(e.response?.data?.message ?? e.message);
      } finally {
        setLoadingTests(false);
      }
    })();
  }, [visible, testId]);

  return (
    <div
      className={`overflow-y-auto fixed right-0 bottom-0 left-0 top-16 bg-cyan-100 rounded-lg ${visible ? "" : "hidden"}`}
    >
      <div className="relative w-full">
        <div className="flex sticky top-0 z-50 py-6 pl-10 text-lg bg-cyan-100">
          Testing History for DeviceId:{" "}
          <span
            onClick={onClose}
            className="ml-2 text-xl font-semibold text-indigo-900"
          >
            {deviceId}
          </span>
          <div className="mx-3 w-px bg-gray-700"> </div>
          <span className="">{scenario}</span>
          {subscenario && subscenario !== "" && (
            <>
              <div className="mx-3 w-px bg-gray-700"> </div>
              <span className="">{subscenario}</span>
            </>
          )}
          <div className="mx-10 bg-black w-[2px]"> </div>
          <div className="mr-2">Filter: </div>
          <input
            type="date"
            value={filterDate}
            onChange={(e) => setFilterDate(e.target.value)}
            className="p-1.5 text-sm"
          />
          <IoMdClose
            onClick={onClose}
            className="fixed right-10 top-24 text-2xl cursor-pointer"
          />
        </div>
        <div className="pb-10 space-y-5 w-full" id="container">
          {testDetails ? (
            <div className="px-10 w-full h-full">
              <div className="flex gap-3 items-center transition cursor-pointer hover:text-indigo-800">
                <div
                  onClick={() => {
                    setTestDetails(null);
                    setTestId(null);
                  }}
                  className="flex gap-1 items-center"
                >
                  <FaChevronLeft />
                  <span>View List</span>
                </div>
                <div className="grow"></div>
                <CSVLink
                  data={testDetails ?? []}
                  filename={`${testId}_${new Date().toISOString()}.csv`}
                  target="_blank"
                  className="flex gap-1 items-center py-1 px-2 bg-indigo-200 rounded"
                >
                  Download CSV
                  <FaArrowDown />
                </CSVLink>
              </div>
              <div className="overflow-y-auto p-5 mt-2 space-y-5 w-full h-full min-h-full bg-white rounded">
                <div className="p-8 w-full">
                  <table className="w-full">
                    <tbody>
                      <tr className="text-gray-400 border-b border-gray-500">
                        <th>Checks</th>
                        <th>Status</th>
                        <th>Last Run</th>
                        <th>Duration(s)</th>
                        <th>Comments</th>
                        <th>Result</th>
                      </tr>
                      {testDetails.map((log, i) => (
                        <tr key={i} className="py-3">
                          <td className="text-lg capitalize text-sky-700">
                            {" "}
                            {log.check}{" "}
                          </td>
                          <td className="capitalize">{log.status}</td>
                          <td>{new Date(log.last_run).toLocaleString()}</td>
                          <td>{log.duration}</td>
                          <td className="whitespace-pre-wrap">
                            {log.comments}
                          </td>
                          <td className="uppercase result">
                            <div
                              className={`${log.result && (log.result === "success" ? "bg-green-500" : log.result === "failed" ? "bg-red-500" : "bg-orange-500")}`}
                            >
                              {log.result}
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          ) : loadingTests ? (
            <div className="flex justify-center items-center w-full h-full">
              <ImSpinner2 className="text-5xl animate-spin" />
            </div>
          ) : noItems ? (
            <div className="w-full h-full text-xl">
              <p className="my-20 text-center">No Logs Found</p>
              {pageNumber > 1 && (
                <div className="flex gap-5 justify-center items-center w-full">
                  <div
                    className="flex gap-1 items-center py-1 px-2 bg-indigo-200 rounded cursor-pointer"
                    onClick={() => setPageNumber(pageNumber - 1)}
                  >
                    <FaChevronLeft />
                    <span>Prev</span>
                    <div className="px-3 bg-indigo-100">{pageNumber}</div>
                  </div>
                </div>
              )}
            </div>
          ) : (
            <>
              {testList.map((log, i) => {
                let duration = Math.floor(
                  (new Date(log.end_timestamp) -
                    new Date(log.start_timestamp)) /
                    1000,
                );
                if (duration >= 60)
                  duration =
                    Math.floor(duration / 60) + "m " + (duration % 60) + "s";
                else duration = duration + "s";

                return (
                  <div
                    className="flex justify-between gap-5 py-5 px-10 mx-10 bg-white rounded-lg shadow-lg cursor-pointer transition hover:scale-[102%]"
                    key={i}
                    onClick={() => setTestId(log.test_id)}
                  >
                    <div>
                      <div>Start: </div>
                      <div>
                        {new Date(log.start_timestamp).toLocaleString("en-GB")}
                      </div>
                    </div>
                    <div>
                      <div>End: </div>
                      <div>
                        {new Date(log.end_timestamp).toLocaleString("en-GB")}
                      </div>
                    </div>
                    <div>
                      <div>Duration: </div>
                      <div>{duration}</div>
                    </div>
                    <div>
                      <div>Test Id: </div>
                      <div>{log.test_id}</div>
                    </div>
                  </div>
                );
              })}
              <div className="flex gap-5 justify-center items-center w-full">
                {pageNumber > 1 && (
                  <div
                    className="flex gap-1 items-center py-1 px-2 bg-indigo-200 rounded cursor-pointer"
                    onClick={() => setPageNumber(pageNumber - 1)}
                  >
                    <FaChevronLeft />
                    <span>Prev</span>
                  </div>
                )}
                <div className="px-3 bg-indigo-100">{pageNumber}</div>
                {testList?.length === pageSize && (
                  <div
                    className="flex gap-1 items-center py-1 px-2 bg-indigo-200 rounded cursor-pointer"
                    onClick={() => setPageNumber(pageNumber + 1)}
                  >
                    <span>Next</span>
                    <FaChevronRight />
                  </div>
                )}
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
}
