import { useLocation, useNavigate } from "react-router-dom";

const links = {
  "/": "Home",
  "/testing": "Integration Testing",
  "/device": "Device Config",
  "/debug": "Debug",
};

export default function Navbar() {
  const location = useLocation();
  const navigate = useNavigate();

  function gotoRoute(route) {
    // if (isTesting) {
    //   toast.error("Please stop testing before changing routes");
    //   return;
    // }
    navigate(route);
  }

  return (
    <div className="flex fixed top-0 z-50 gap-10 justify-center py-5 px-5 w-full text-xl bg-white shadow">
      {Object.keys(links).map((link) => (
        <button
          key={link}
          to={link}
          className={`${location.pathname === link ? "text-indigo-500 font-bold" : ""}`}
          onClick={() => gotoRoute(link)}
        >
          {links[link]}
        </button>
      ))}
    </div>
  );
}
