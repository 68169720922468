import axios from "axios";
import { useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import { IoMdClose } from "react-icons/io";
import { useDebugContext } from "../../context/DebugContext";
import { FaArrowDown, FaChevronLeft, FaChevronRight } from "react-icons/fa6";
import { ImSpinner2 } from "react-icons/im";

export default function LogHistoryModal({ visible, onClose }) {
  const { debugDeviceId } = useDebugContext();
  const [logsList, setLogsList] = useState([]);
  const [logsInstanceId, setLogsInstanceId] = useState();
  const [debugMessages, setDebugMessages] = useState();
  const [loadingLogs, setLoadingLogs] = useState(false);
  const [filterDate, setFilterDate] = useState("");
  const [pageNumber, setPageNumber] = useState(1);
  const [noItems, setNoItems] = useState(false);
  const pageSize = 10;

  function downloadTextFile(filename, text) {
    const element = document.createElement("a");
    element.setAttribute(
      "href",
      `data:text/plain;charset=utf-8,${encodeURIComponent(text)}`,
    );
    element.setAttribute("download", filename);
    element.style.display = "none";
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
  }
  async function handlePageClick(data) {
    setPageNumber(data.selected + 1);
  }

  useEffect(() => {
    if (!visible || !debugDeviceId) return;
    let API = new URL(
      `${process.env.REACT_APP_SCRIPT_API_URL}/debug/logs-list/${debugDeviceId}`,
    );
    if (filterDate && filterDate !== "")
      API.searchParams.append("date", filterDate);
    API.searchParams.append("pageNumber", pageNumber);
    API.searchParams.append("pageSize", pageSize);
    setLoadingLogs(true);
    (async () => {
      try {
        let res = await axios.get(API.toString());
        if (res.status !== 200)
          throw new Error(res.data?.data?.message ?? res.statusText);
        setLogsList(res.data);
        setNoItems(res.data.length === 0);
      } catch (e) {
        console.error(e);
        toast.error(e.response?.data?.message ?? e.message);
      } finally {
        setLoadingLogs(false);
      }
    })();
  }, [visible, debugDeviceId, filterDate, pageNumber]);

  useEffect(() => {
    setPageNumber(1);
    setNoItems(false);
  }, [filterDate, debugDeviceId]);

  useEffect(() => {
    setLogsList([]);
    setLogsInstanceId();
    setDebugMessages();
    setNoItems(false);
  }, [debugDeviceId]);

  useEffect(() => {
    if (!logsInstanceId || !visible) return;
    setLoadingLogs(true);
    (async () => {
      try {
        let res = await axios.get(
          `${process.env.REACT_APP_SCRIPT_API_URL}/debug/logs/${logsInstanceId}`,
        );
        if (res.status !== 200)
          throw new Error(res.data?.data?.message ?? res.statusText);
        setDebugMessages(res.data);
        if (res.data.length === 0) setNoItems(true);
        document.getElementById("container").scrollTop = 0;
      } catch (e) {
        console.error(e);
        toast.error(e.response?.data?.message ?? e.message);
      } finally {
        setLoadingLogs(false);
      }
    })();
  }, [visible, logsInstanceId]);

  return (
    <div
      className={`overflow-y-auto fixed right-0 bottom-0 left-0 top-16 bg-cyan-100 rounded-lg ${visible ? "" : "hidden"}`}
    >
      <div className="relative w-full">
        <div className="flex sticky top-0 z-50 py-6 pl-10 text-lg bg-cyan-100">
          Debug Log History for DeviceId:{" "}
          <span
            onClick={onClose}
            className="ml-2 text-xl font-semibold text-indigo-900"
          >
            {debugDeviceId}
          </span>
          <div className="mx-10 bg-black w-[2px]"> </div>
          <div className="mr-2">Filter: </div>
          <input
            type="date"
            onChange={(e) => setFilterDate(e.target.value)}
            className="p-1.5 text-sm"
          />
          <IoMdClose
            onClick={onClose}
            className="fixed right-10 top-24 text-2xl cursor-pointer"
          />
        </div>
        <div className="pb-10 space-y-5 w-full" id="container">
          {debugMessages ? (
            <div className="px-10 w-full h-full">
              <div className="flex gap-3 items-center transition cursor-pointer hover:text-indigo-800">
                <div
                  onClick={() => {
                    setDebugMessages(null);
                    setLogsInstanceId(null);
                  }}
                  className="flex gap-1 items-center"
                >
                  <FaChevronLeft />
                  <span>View List</span>
                </div>
                <div className="grow"></div>
                <span
                  className="flex gap-1 items-center py-1 px-2 bg-indigo-200 rounded"
                  onClick={() => {
                    let text = debugMessages
                      .map(
                        (log) =>
                          `${new Date(log.timestamp).toLocaleString("en-GB")}\n${log.debug_response}`,
                      )
                      .join("\n\n");
                    downloadTextFile("debug-log.txt", text);
                  }}
                >
                  Download Log
                  <FaArrowDown />
                </span>
              </div>
              <div className="overflow-y-auto p-5 mt-2 space-y-5 w-full h-full min-h-full bg-white rounded">
                {debugMessages.map((log, i) => (
                  <div key={i} className="">
                    <div className="text-sm">
                      {new Date(log.timestamp).toLocaleString("en-GB")}
                    </div>
                    <div
                      className="font-mono whitespace-pre-wrap"
                      dangerouslySetInnerHTML={{
                        __html: String.raw`${log.debug_response
                          ?.replaceAll("\\\\", "\\")
                          .replaceAll("\\n", "<br />")
                          .replaceAll(
                            "\\t",
                            "&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;",
                          )
                          .replaceAll("\\r", "")}`,
                      }}
                    ></div>
                  </div>
                ))}
              </div>
            </div>
          ) : loadingLogs ? (
            <div className="flex justify-center items-center w-full h-full">
              <ImSpinner2 className="text-5xl animate-spin" />
            </div>
          ) : noItems ? (
            <div className="w-full h-full text-xl">
              <p className="my-20 text-center">No Logs Found</p>
              {pageNumber > 1 && (
                <div className="flex gap-5 justify-center items-center w-full">
                  <div
                    className="flex gap-1 items-center py-1 px-2 bg-indigo-200 rounded cursor-pointer"
                    onClick={() => setPageNumber(pageNumber - 1)}
                  >
                    <FaChevronLeft />
                    <span>Prev</span>
                    <div className="px-3 bg-indigo-100">{pageNumber}</div>
                  </div>
                </div>
              )}
            </div>
          ) : (
            <>
              {logsList.map((log, i) => (
                <div
                  className="flex justify-between gap-5 py-5 px-10 mx-10 bg-white rounded-lg shadow-lg cursor-pointer transition hover:scale-[102%]"
                  key={i}
                  onClick={() => setLogsInstanceId(log.instance_id)}
                >
                  <div>
                    <div>Start: </div>
                    <div>
                      {new Date(log.start_timestamp).toLocaleString("en-GB")}
                    </div>
                  </div>
                  <div>
                    <div>End: </div>
                    <div>
                      {new Date(log.end_timestamp).toLocaleString("en-GB")}
                    </div>
                  </div>
                  <div>
                    <div>Instance Id: </div>
                    <div>{log.instance_id}</div>
                  </div>
                </div>
              ))}
              <div className="flex gap-5 justify-center items-center w-full">
                {pageNumber > 1 && (
                  <div
                    className="flex gap-1 items-center py-1 px-2 bg-indigo-200 rounded cursor-pointer"
                    onClick={() => setPageNumber(pageNumber - 1)}
                  >
                    <FaChevronLeft />
                    <span>Prev</span>
                  </div>
                )}
                <div className="px-3 bg-indigo-100">{pageNumber}</div>
                {logsList?.length === pageSize && (
                  <div
                    className="flex gap-1 items-center py-1 px-2 bg-indigo-200 rounded cursor-pointer"
                    onClick={() => setPageNumber(pageNumber + 1)}
                  >
                    <span>Next</span>
                    <FaChevronRight />
                  </div>
                )}
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
}
